import React, { useState, useEffect } from 'react';
import { Typography, Grid, LinearProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Fade } from 'react-reveal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  role: {
    color: '#208AAE',
  },
  progress: {
    backgroundColor: '#ECEFF1',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#160b6e',
    },
  },
  waveEmoji: {
    animationName: '$wave-animation',
    animationDuration: '2.5s',
    animationIterationCount: 'infinite',
    transformOrigin: '70% 70%',
    display: 'inline-block',
  },
  bodyText: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '65%',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '55%',
    },
    paddingBottom: '5vh',
  },
  '@keyframes wave-animation': {
    '0%': { transform: 'rotate( 0.0deg)' },
    '10%': { transform: 'rotate(14.0deg)' },
    '20%': { transform: 'rotate(-8.0deg)' },
    '30%': { transform: 'rotate(14.0deg)' },
    '40%': { transform: 'rotate(-4.0deg)' },
    '50%': { transform: 'rotate(10.0deg)' },
    '60%': { transform: 'rotate(0.0deg)' },
    '100%': { transform: 'rotate(0.0deg)' },
  },
}));

const roles = ['Student', 'Web Developer', 'Programmer', 'Tinkerer', 'Computer Scientist', 'Problem Solver'];

const Home = () => {
  const classes = useStyles();
  const [role, setRole] = useState('Student');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setRole((oldRole) => {
        const newIndex = (roles.indexOf(oldRole) + 1) % roles.length;
        return roles[newIndex];
      });
      setProgress(0);
    }, 4000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 400);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <Fade delay={500}>
          <Typography variant="h2">
            Hi, I am Rami <span className={classes.waveEmoji}>👋</span>!
          </Typography>
        </Fade>
        <Fade delay={1000}>
          <Typography variant="h6">Computer Science @ the University of Wisconsin, Madison</Typography>
        </Fade>
        <Fade delay={1500}>
          <LinearProgress variant="determinate" value={progress} className={classes.progress} />
        </Fade>
        <Fade delay={2000}>
          <Typography variant="h4">
            I am a{' '}
            <Box component="span" className={classes.role}>
              {role}
            </Box>
          </Typography>
        </Fade>
        <Fade delay={3000}>
          <Typography variant="body1" className={classes.bodyText}>
            I am a motivated and ambitious rising sophomore studying Computer Science at the University
            of Wisconsin - Madison. I have a deep passion for creating and tinkering with technology, whether it's developing full-stack
            applications, designing systems programs, or crafting mobile apps.
            The ability to bring ideas to life through my own skills and designs is incredibly fulfilling to me. I am
            driven by my love for technology and constantly seek out new opportunities to expand my knowledge and
            expertise.
            <br />
            <br />
            Nice to meet you 😊!
          </Typography>
        </Fade>
      </Grid>
    </Grid>
  );
};

export default Home;
