import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from "@material-ui/core";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import { DiJava, DiPython, DiPostgresql, DiJavascript, DiHtml5, DiCss3, DiDjango, DiReact, DiGit, DiDocker, DiLinux } from "react-icons/di";
import { SiNodedotjs, SiExpress, SiC } from 'react-icons/si';


const useStyles = makeStyles((theme) => ({
  timelineDot: {
    backgroundColor: '#000080', // Navy color
    width: theme.spacing(4), // Increase size of timeline dot
    height: theme.spacing(4), // Increase size of timeline dot
  },
  timelineConnector: {
    backgroundColor: '#000080', // Navy color
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  strip: {
    padding: theme.spacing(2),
  },
  strip2: {
    backgroundColor : 'rgba(222, 244, 252, 0.2)',
  },
  skillContainer: {
    backgroundColor: '#b5e1ff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    height: theme.spacing(8),
    width: theme.spacing(8),
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
      transform: 'scale(1.05)',
    },
  },
  skillIcon: {
    fontSize: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Resume = () => {
  const classes = useStyles();

  const skills = [
    <DiJava />, <DiPython />, <SiC />, <DiPostgresql />, <DiJavascript />,
    <DiHtml5 />, <DiCss3 />, <DiDjango />, <SiNodedotjs />, <SiExpress />,
    <DiReact />, <DiGit />, <DiDocker />, <DiLinux />
  ];
  
  return (
    <div style={{ paddingBottom: '5vh' }}>
      <Typography variant="h3">Resume</Typography>
      <Typography variant="h5">Experience</Typography>
      <Timeline>
      <TimelineItem>
          <TimelineOppositeContent style={{flex: 0}}/>
          <TimelineSeparator>
            <TimelineDot className={classes.timelineDot} />
            <TimelineConnector className={classes.timelineConnector} />
          </TimelineSeparator>
          <TimelineContent>
            <Box className={classes.boxContent}>
              <Typography variant="h6">Meta (Facebook) & Major League Hacking</Typography>
              <Typography variant="subtitle1">Site Reliability Engineering Fellow</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                June 2023 - Sept. 2023 
              </Typography>
              <Typography variant="p">
                • Selected as one of approximately 120 Major League Hacking Fellows from a pool of over 3000+ applicants, and specifically as one of 45 working with Meta <br></br>
                • Recieved direct mentorship from Meta engineers through the MLH Fellowship regarding SRE and Production Engineering including multiple deep dives into modern PE solutions at Meta <br></br>
                • Developed an open-source portfolio Flask application configured on a Virtual Private Server (VPS) with automated deployment and testing through GitHub Actions and bash scripts; achieved 88% unit test coverage <br></br>
                • Optimized system performance by containerizing the application, conducting load tests through Apache Bench, and implementing database caching, alerting, and monitoring using Prometheus and Grafana resulting in a 24% reduction in overall system latency <br></br>
                • Configured and troubleshooted Linux systems using tools such as top, vmstat, iostat, systemd, and cron to maintain an optimal production environment <br></br>
              </Typography>
            </Box>
          </TimelineContent>
      </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent style={{flex: 0}}/>
          <TimelineSeparator>
            <TimelineDot className={classes.timelineDot} />
            <TimelineConnector className={classes.timelineConnector} />
          </TimelineSeparator>
          <TimelineContent>
          <Box className={classes.boxContent}>
              <Typography variant="h6">Bloomberg</Typography>
              <Typography variant="subtitle1">Accelerator Summer School</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                June 2023
              </Typography>
              <Typography variant="p">
                • Selected for an exclusive one-month program providing an insider's look into recruiting, technical interview best practices and engineering culture at Bloomberg <br></br>
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent style={{flex: 0}}/>
          <TimelineSeparator>
            <TimelineDot className={classes.timelineDot} />
          </TimelineSeparator>
          <TimelineContent>
          <Box className={classes.boxContent}>
              <Typography variant="h6">Capital One</Typography>
              <Typography variant="subtitle1">Software Engineering Summmit</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                May 2023
              </Typography>
              <Typography variant="p">
                • Gained hands-on experience in various technical workshops, including Git, React.js, Node.js, Express.js, PostgreSQL, and Arduino <br></br>
                • Placed first place in the hackathon component of the Capital One Software Engineering Summit (team of 5) with FinBot, a chatbot designed for college students to enhance personal finance and financial literacy <br></br>
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Box className={classes.strip} style={{ paddingTop: '5vh' }}>
        <Typography variant="h5" className={classes.skillsContainer}>
          Technologies I am familiar with:
        </Typography>
        <Grid container justifyContent="center" className={classes.strip2} style={{ paddingTop: '2vh' }}>
          {skills.map((skill, index) => (
            <Grid item key={index}>
              <Box className={classes.skillContainer}>
                <Box className={classes.skillIcon}>
                  {skill}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Resume;
