import React, { useState, useEffect, useRef } from "react";
import { Avatar, CssBaseline, createTheme, ThemeProvider, Container, ListItemIcon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import { Build as ProjectsIcon, Description as ResumeIcon, Mail as ContactIcon } from "@material-ui/icons";
import { Person as PersonIcon } from "@material-ui/icons";
import { Events, scroller, Element } from 'react-scroll';

import { Fade } from 'react-reveal';

import Home from './Home';
import Projects from './Projects'
import Resume from './Resume'
import Contact from './Contact'

import './App.css';

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      main: "#208AAE",
    },
    secondary: {
      main: "#ff4081",
    },
  },
  typography: {
    fontSize: 18
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1600,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#030530",
    color: "white",
  },
  listItemText: {
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      fontWeight: 700,
    }
  },
  listItemIcon: {
    minWidth: '30px', 
    color: 'white',
    marginRight: theme.spacing(2),  // Add more spacing between icon and text
  },
  listItem: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
    },
    marginBottom: theme.spacing(4),  // Increase space between items
  },
  name: {
    color: "#b5e1ff",  // Set color of your name
    paddingBottom: theme.spacing(10),  // Add some space below your name
  },
  avatar: {
    margin: "20px auto",
    width: theme.spacing(15),
    height: theme.spacing(15),
    boxShadow: theme.shadows[5],
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "transparent",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidth + theme.spacing(2),
  },
  contentContainer: {
    paddingLeft: '15vw',
    paddingRight: '15vw',
    paddingTop: '2vh',
    paddingBottom: '5vh',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '12vw',
      paddingRight: '12vw',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '8vw',
      paddingRight: '8vw',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5vw',
      paddingRight: '5vw',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '2vw',
      paddingRight: '2vw',
    },
  },
});

function App() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isAvatarHovered, setIsAvatarHovered] = useState(false);

  const menuItems = [
    { text: 'About Me', icon: <PersonIcon /> },
    { text: 'Projects', icon: <ProjectsIcon /> },
    { text: 'Resume', icon: <ResumeIcon /> },
    { text: 'Contact', icon: <ContactIcon /> },
  ];

  useEffect(() => {
    Events.scrollEvent.register('begin', function() {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function() {
      // console.log("end", arguments);
    });

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const scrollTo = (element) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  const homeRef = useRef();
  const projectsRef = useRef();
  const resumeRef = useRef();
  const contactRef = useRef();

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px 0px -50% 0px',  // Observe when bottom of the element is in the middle of the viewport
      threshold: 0,
    };
    const contactObserverOptions = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          switch (entry.target) {
            case homeRef.current:
              setSelectedTab(0);
              break;
            case projectsRef.current:
              setSelectedTab(1);
              break;
            case resumeRef.current:
              setSelectedTab(2);
              break;
            default:
              break;
          }
        }
      });
    };

    const contactObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setSelectedTab(3);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const contactObserver = new IntersectionObserver(contactObserverCallback, contactObserverOptions);

    const homeRefValue = homeRef.current;
    const projectsRefValue = projectsRef.current;
    const resumeRefValue = resumeRef.current;
    const contactRefValue = contactRef.current;

    if (homeRefValue) observer.observe(homeRefValue);
    if (projectsRefValue) observer.observe(projectsRefValue);
    if (resumeRefValue) observer.observe(resumeRefValue);
    if (contactRefValue) observer.observe(contactRefValue);

    return () => {
      if (homeRefValue) observer.unobserve(homeRefValue);
      if (projectsRefValue) observer.unobserve(projectsRefValue);
      if (resumeRefValue) observer.unobserve(resumeRefValue);
      if (contactRefValue) contactObserver.unobserve(contactRefValue);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <Avatar 
            alt="User Image" 
            src={isAvatarHovered ? "https://media.discordapp.net/attachments/1105521310735540245/1112524086535532604/1665676173360.jpg" : "https://cdn.discordapp.com/attachments/1105521310735540245/1112524068177059850/image-gbSvDGDA1-transformed.png"} 
            onMouseEnter={() => setIsAvatarHovered(true)}
            onMouseLeave={() => setIsAvatarHovered(false)}
            className={classes.avatar} 
          />
          <Container maxWidth="sm">
            <Typography variant="h6" align="center" className={classes.name}>
              Rami Elsayed
            </Typography>
          </Container>
          <List>
            {menuItems.map(({ text, icon }, index) => (
              <ListItem
                button
                key={text}
                selected={selectedTab === index}
                onClick={() => { setSelectedTab(index); scrollTo(text); }}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {React.cloneElement(icon, { style: { color: selectedTab === index ? theme.palette.primary.main : 'white' }})}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <div className={classes.contentContainer}>
          <div ref={homeRef}>
            <Element name="Home">
              <Home />
            </Element>
          </div>
          <div ref={projectsRef}>
            <Element name="Projects">
              <Fade delay={3500}>
                <Projects />
              </Fade>
            </Element>
          </div>
          <div ref={resumeRef}>
            <Element name="Resume">
              <Fade delay={500}>
                <Resume />
              </Fade>
            </Element>
          </div>
          <div ref={contactRef}>
            <Element name="Contact">
              <Fade delay={500}>
                <Contact />
              </Fade>
            </Element>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
