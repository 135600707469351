import React from 'react';
import { Typography, IconButton, Link } from '@material-ui/core';
import { GitHub, LinkedIn, Email } from '@material-ui/icons';

const Contact = () => {
  return (
    <div style={{ paddingTop: '15vh', paddingBottom: '10vh'}}>
      <Typography variant="h3">Contact Me</Typography>
      <Typography variant="h4">Feel free to reach out, don't be shy 👇</Typography>
      <Typography variant="h6">Email: ramielsayed965@gmail.com</Typography>
      <Typography variant="h6">Phone: +1-414-808-5102</Typography>
      <Link href="https://github.com/RamiE965">
        <IconButton>
          <GitHub />
        </IconButton>
      </Link>
      <Link href="https://www.linkedin.com/in/ramielsayed965/">
        <IconButton>
          <LinkedIn />
        </IconButton>
      </Link>
      <Link href="mailto:ramielsayed965@gmail.com">
        <IconButton>
          <Email />
        </IconButton>
      </Link>
    </div>
  );
}

export default Contact;
