import React from "react";
import { Card, CardMedia, CardContent, Typography, Chip, Grid, Container, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    paddingBottom: '5vh'
  },
  card: {
    maxWidth: '100%',
    minWidth: 'fit-content',
    height: '100%',
    margin: 'auto',
    marginBottom: 20,
    transition: 'transform 0.3s ease-in-out',
    opacity: 0,
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardAnimate: {
    animation: '$fadeIn 0.5s forwards',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  media: {
    height: 200,
  },
  chip: {
    margin: 2,
  },
});

const projectsData = [
  {
    title: 'Personal Website',
    description: 'Created from scratch using React and Material-UI, without relying on templates or site builders. Explore my portfolio, learn about my background, and get in touch!',
    imageUrl: 'https://images.pexels.com/photos/270360/pexels-photo-270360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    technologies: ['React.js', 'Material-UI', 'JavaScript', 'HTML', 'CSS'],
    githubLink: 'https://ramielsayed.dev' // Change this after deployment
  },
  {
    title: 'FinBot',
    description: 'A chatbot designed for college students to enhance personal finance and financial literacy. Won first place in the Capital One Software Engineering Summit May 2023.',
    imageUrl: 'https://media.discordapp.net/attachments/1103186729441173575/1112269326439948379/screenshot_2023-05-19_at_00.png?width=1143&height=905',
    technologies: ['React.js', 'Node.js', 'Express.js', 'OpenAI API', 'JavaScript', 'HTML', 'CSS'],
    githubLink: 'https://github.com/RamiE965/personal-finance-chatbot-assistant.github.io'
  },
  {
    title: 'Pure3D -- A Java 3D Renderer',
    description: 'An elementary pure Java (no dependancy) Orthographic Projection 3D Renderer.',
    imageUrl: 'https://camo.githubusercontent.com/77ed056cd1958816efae6f467c1c1bd6d474747241cf297d14e6a4c1fb9db37c/68747470733a2f2f63646e2e646973636f72646170702e636f6d2f6174746163686d656e74732f313038313432313135313139343037353134362f313038313938333637303639383635313832382f696d6167652e706e67',
    technologies: ['Java', 'Java Swing'],
    githubLink: 'https://github.com/RamiE965/Pure3D'
  },
  {
    title: 'Zipper',
    description: 'A twitter-like social media web application that allows users to post tweets, like, follow, block and view profile pages built using Django.',
    imageUrl: 'https://media.discordapp.net/attachments/1103186729441173575/1112266583503544331/image.png?width=975&height=905',
    technologies: ['Django', 'SQL', 'Bootstrap', 'JavaScript', 'Python', 'HTML', 'CSS'],
    githubLink: 'https://github.com/RamiE965/zipper/tree/main'
  }
];

const ProjectCard = ({ project }) => {
  const classes = useStyles();
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true,
  });

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} ref={ref}>
      <Card className={`${classes.card} ${inView ? classes.cardAnimate : ''}`}>
        <Link href={project.githubLink} target="_blank" rel="noopener">
          <CardMedia
            className={classes.media}
            image={project.imageUrl}
            title={project.title}
          />
        </Link>
        <CardContent>
          <Typography variant="h5" component="h2">
            {project.title}
          </Typography>
          {project.technologies.map((tech, index) => (
            <Chip
              key={index}
              label={tech}
              variant="outlined"
              className={classes.chip}
            />
          ))}
          <Typography variant="body2" color="textSecondary" component="p">
            {project.description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Projects = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h3" align="center">Projects</Typography>
      <br></br>
      <Typography variant="h6" align="left">Here are some of my projects:</Typography>
      <br></br>
      <Grid container spacing={4} justifyContent="center">
        {projectsData.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </Grid>
    </Container>
  );
}

export default Projects;
